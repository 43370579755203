var ProjectName = {
    init: function (){
        this._initFoundation();
    },
    _initFoundation: function () {
        $(document).foundation();
    },
}

jQuery(document).ready(function () {
    
    ProjectName.init();
        
});